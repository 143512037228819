<template>
  <div class="card">
    <div class="card-header border-0">
      <h3 class="mb-0 text-left">Events</h3>
      <div class="col-12">
        <fieldset class="border p-3 rounded bg-silver">
          <legend>Search Event</legend>
          <form @submit.prevent="searchHandler">
            <div class="form-row">
              <div class="form-group col-md-1">
                <span class="form-control-label">Display</span> <br/>
                <label class="custom-toggle">
                  <input
                      v-model="form.display"
                      class="form-control"
                      type="checkbox"
                  />
                  <span
                      class="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                  ></span>
                </label>
              </div>

              <div class="form-group col-md-1">
                <span class="form-control-label">Recurring</span> <br/>
                <label class="custom-toggle">
                  <input
                      v-model="form.recurring"
                      class="form-control"
                      type="checkbox"
                  />
                  <span
                      class="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                  ></span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-3">
                <label for="inputCity">Event Name</label>
                <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    id="inputCity"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="inputCity">Game UUID</label>
                <input
                    v-model="form.uuid"
                    type="text"
                    class="form-control"
                    id="inputUuid"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="inputState">Event Type</label>
                <select
                    v-model="form.type"
                    id="inputState"
                    class="form-control"
                >
                  <option value="">Any</option>
                  <option
                      v-for="(option, i) in dropdowns.eventTypes"
                      :key="i"
                      :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="inputZip">Event Start Date</label>
                <input
                    v-model="form.date"
                    type="date"
                    class="form-control"
                    id="inputZip"
                />
              </div>

              <div class="form-group col-md-2 pt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead class="thead-light">
        <tr class="text-left">
          <th scope="col" width="120">Actions</th>
          <th class="sort" data-sort="name" scope="col">Event Name</th>
          <th class="sort" data-sort="name" scope="col">Route</th>
          <th class="sort" data-sort="name" scope="col">
            Gender / Minimum Weight
          </th>
          <th class="sort" data-sort="name" scope="col">Type / Duration</th>
          <th class="sort" data-sort="name" scope="col">
            Distance / Elevation
          </th>
          <th class="sort" data-sort="name" scope="col">
            Start / Closing Time
          </th>
        </tr>
        </thead>
        <tbody class="list">
        <tr
            class="text-left"
            v-for="event of events.data"
            :key="event._id"
            :class="{ isNew: event.isNew }"
        >
          <td class="text-left">
            <div class="btn-group" role="group" aria-label="Basic example">
              <router-link
                  class="btn btn-sm btn-outline-primary"
                  :to="{ name: 'AdminEventsEdit', params: { id: event._id } }"
              >
                Edit
              </router-link>
            </div>
            <div class="mt-1 p-0">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link
                        class="btn btn-sm btn-outline-light"
                        style="display: none"
                        :to="{
                      name: 'EventWordpressData',
                      params: { id: event._id },
                    }"
                >
                  Wordpress Data
                </router-link>
                <router-link
                    class="btn btn-sm btn-outline-light"
                    :to="{
                      name: 'AdminEventsParticipants',
                      params: { id: event._id },
                    }"
                >
                  Participants
                </router-link>
                <router-link
                    class="btn btn-sm btn-outline-light"
                    :to="{
                      name: 'AdminEventsImages',
                      params: { id: event._id },
                    }"
                >
                  Images / Files
                </router-link>
              </div>
            </div>
          </td>
          <td>
            <strong class="text-warning mr-2">{{ event.position }}.</strong>
            <span class="badge badge-warning">{{ event.serverEventId }}</span><br/>
            {{ event.name }}<br/>

            <div class="ml-0">
              <span class="badge badge-primary mr-1" v-if="event.otp">OTP</span>
              <span class="badge badge-primary mr-1" v-if="event.apply_cap">CAP</span>
              <span class="badge badge-primary mr-1" v-if="event.team">TEAM</span>
              <span class="badge badge-primary mr-1" v-if="event.display">Display</span>
              <span class="badge badge-primary mr-1" v-if="event.registration_required">Registration</span>
              <br/>
              <span class="badge badge-primary mr-1" v-if="event.identification">Identification</span>
              <span class="badge badge-primary mr-1" v-if="event.isRecurring">Recurring</span>
            </div>
          </td>
          <td>{{ getRoute(event.route) }}</td>
          <td>
            {{ getGender(event.gender) }} <br/>
            {{ event.minimum_weight }} KGs
          </td>
          <td>
            <span class="text-uppercase">{{ event.event_type }}</span>
            <br/>{{ event.total_duration }}
            Minutes
          </td>
          <td>
            {{ event.distance }} KMs <br/>
            {{ event.elevation }} Meters
          </td>
          <td>
            {{ event.datetime | dateFormat }} <br/>
            {{ event.closing_datetime | dateFormat }}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="mx-auto">
        <pagination
            v-model="page"
            :records="total"
            :per-page="perPage"
            @paginate="getEvents"
            theme="bootstrap4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import * as dayjs from "dayjs";
import eventTypes from "@/json/eventTypes.json";
import Pagination from "vue-pagination-2";

export default {
  name: "AdminPagesList",
  data() {
    return {
      page: 1,
      form: {
        display: false,
        registration: false,
        otp: false,
        identification: false,
        teams: false,
        recurring: false,
        type: "",
        name: null,
        date: null,
      },
      dropdowns: {
        eventTypes,
      },
    };
  },

  components: {
    Pagination,
  },

  computed: {
    ...mapGetters("event", ["events"]),
    ...mapGetters("routes", ["getRoutes"]),

    total() {
      if (!this.events) return 0;
      return parseInt(this.events.total);
    },

    perPage() {
      if (!this.events) return 0;
      return parseInt(this.events.per_page);
    },
  },

  async mounted() {
    this.$store.dispatch("event/events", {page: this.page});
    if(this.getRoutes.length === 0) {
      await this.$store.dispatch("routes/loadRoutes");
    }
  },

  filters: {
    dateFormat: function (value) {
      return dayjs(value).format("DD MMM YYYY, hh:mm A");
    },
  },

  methods: {
    getEvents(page = 1) {
      this.page = page;
      this.$store.dispatch("event/events", {
        page: this.page,
        form: this.form,
      });
    },

    getGender(g) {
      const genders = {0: "Male", 1: "Female", 2: "Both"};
      return genders[g];
    },

    getRoute(r) {
      const route = this.getRoutes.find(
          (route) => parseInt(route.value) === parseInt(r)
      );
      return route?.label;
    },

    searchHandler() {
      this.form.filters = true;
      this.page = 1;
      this.$store.dispatch("event/events", {
        form: this.form,
        page: this.page,
      });
    },
    
  },
};
</script>

<style scoped>
.bg-silver {
  background-color: #f7f7f7;
}

.table td {
  padding: 0.5rem;
}

legend {
  font-size: 16px;
}

.isNew {
  background: linear-gradient(-45deg, #ffffff, #cccccc, #ffffff);
  background-size: 150% 100%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.VuePagination {
  padding: 34px;
  text-align: center;
}

.VuePagination__count.VuePagination__count {
  color: #2d8968;
}
</style>
<style>
.VuePagination__pagination {
  display: inline-flex;
}
</style>
